const baseSlugify = require('slugify');

function slugify(string) {
  return baseSlugify(string, {
    lower: true,
    remove: /[^a-zA-Z0-9\s]/,
  });
}

module.exports = slugify;
