import * as React from "react"
import { graphql, Link as GatsbyRouter } from "gatsby"
import { Image, Box, Text, Grid, Link } from "@chakra-ui/react"
import slugify from "../utils/slugify"
import PageLayout from "../components/PageLayout/PageLayout"
import SEO from "../components/seo/Seo"

export default function Home({ data }) {
  const { nodes } = data.allPrismicProject
  const basicImage = nodes[0].data.images[0].image.thumbnails.icon.url

  return (
    <PageLayout>
      <SEO
        title="Projetos"
        description="Listagem de projetos desenvolvidos pelo nosso escritório de arquitetura"
        image={basicImage}
      />
      <Grid
        gridTemplateColumns={[
          "repeat(1, minmax(0, 1fr))",
          "repeat(2, minmax(0, 1fr))",
          "repeat(3, minmax(0, 1fr))",
          "repeat(4, minmax(0, 1fr))",
        ]}
        gridGap={["1.6rem", "2rem", "2.4rem"]}
      >
        {nodes.map((node, index) =>
          node.data.title && node.data.images[0].image.thumbnails.icon.url ? (
            <Box key={`${node.id}-${index}`} _hover={{ color: "red.600" }}>
              <Link as={GatsbyRouter} to={slugify(node.data.title)}>
                <Image
                  src={node.data.images[0].image.thumbnails.icon.url}
                  alt={node.data.images[0].image.thumbnails.icon.url}
                  ignoreFallback
                  sx={{
                    transition: "0.4s",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                />
              </Link>
              <Text fontWeight={300} color="inherit">
                {node.data.title}
              </Text>
            </Box>
          ) : null
        )}
      </Grid>
    </PageLayout>
  )
}

export const query = graphql`
  query IndexPageQuery {
    allPrismicProject(sort: { fields: data___date, order: DESC }) {
      nodes {
        id
        data {
          title
          images {
            image {
              thumbnails {
                icon {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }
  }
`
